// Place all the styles related to the b2b controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

@import './variables.scss';
@import './hiboo_defaults.scss';

$css--font-face: false;
$css--helpers: false;
$css--body: false;
$css--use-layer: false;
$css--reset: false;
$css--typography: false;
$css--plex: false;

@import '~mapbox-gl-v3/dist/mapbox-gl.css';
@import '~react-spring-bottom-sheet/dist/style.css';
@import '~react-dates/lib/css/_datepicker.css';

@import './bottom_sheet_override.scss';
@import './react_dates_overrides.scss';

:root {
  --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body,
html {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: hidden;

  color: #2d2f30;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;

  @media screen and (max-width: 991px) {
    background: #ffffff;
  }
}

*:focus {
  outline: none;
}

.MobileDisclaimer {
  display: none;
}

@media (max-width: 767.98px) {
  .MobileDisclaimer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 1000;
    padding: 0 5%;

    background-color: $blue;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

#root {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @media print {
    max-height: max-content;
    overflow: hidden;
  }
}

// Dropdowns
.dropdown-menu .dropdown-item {
  cursor: pointer;

  &.dropdown-no-action:hover,
  &.dropdown-no-action:active {
    background-image: none;
    background-color: white !important;
    color: $gray-900 !important;
    cursor: default;
  }
}

// Mapbox

.mapbox-marker {
  background-color: $blue;
  box-shadow: 0 1px 10px 3px rgba(0, 0, 0, 0.13);
  border: solid 2.8px #ffffff;
  border-radius: 30px;
  cursor: pointer;

  &.not-in-last-track {
    background-color: $green;
  }
}
.mapboxgl-popup-content {
  padding: 0 !important;

  .tooltip-content {
    border-radius: $border-radius;
    border-left: 4px solid $blue;
    padding: 10px;
    display: flex;
    flex-direction: column;
    max-width: 220px;
    cursor: auto;

    .tooltip-title {
      flex: 1;
      color: $blue;
    }
    .tooltip-subtitle {
      color: $medium-grey;
      font-size: 12px;
      margin-bottom: 6px;
    }
    .tooltip-description {
      font-size: 14px;
    }
    .tooltip-settings {
      padding-top: 2px;
    }
  }
}

.ClusterMarker__circle {
  background-color: $blue;
  border: 5px solid white;
  border-radius: 50%;
  font-size: 20;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

#MapTextureButton,
#MarkerDisplayButton,
#layersToggle,
#MapResetViewButton,
#MapFullScreenButton,
#MapExtraDataToggleButton {
  button {
    color: inherit;
    box-shadow: none;
    text-decoration: none;

    &.active {
      font-weight: 700;
    }
  }
}

.AlertsList {
  .list-group-item {
    border: none;
  }

  .list-group-item-action {
    cursor: pointer;

    &.active {
      color: $blue;
      font-weight: 700;
    }
  }
}

.AlertForm {
  .block-content {
    overflow-y: auto;
  }
}

.DeviceSelect.is-invalid ~ .invalid-feedback {
  display: block;
}

#geocoder .mapboxgl-ctrl-geocoder {
  min-width: 100%;
  box-shadow: none;

  .geocoder-pin-right * {
    top: 5px;
  }

  .geocoder-icon-search {
    display: none;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    top: 4px;
    left: 4px;
  }

  input {
    @extend .form-control;
    @extend .form-control-sm;

    height: auto;
    padding-left: 25px;
  }
}

.MapDraw {
  .mapboxgl-ctrl-top-left,
  .mapboxgl-ctrl-top-right,
  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    z-index: 0;
  }
}

// Migrate css from hiboo-style

// _type.scss
h2,
h3,
h4,
h5,
h6 {
  color: $strong-blue;
}

// _nav
.nav-item h6 {
  font-weight: 700;
}
.nav-item.active h6 {
  color: $blue;
}

// _block
.block {
  z-index: 3;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  border-radius: $border-radius;

  .block-header {
    border-bottom: 1px solid $gray-300;
    padding: 12px 16px;
    flex-shrink: 0;
    .title {
      font-size: 20px;
      color: $strong-blue;
    }
  }

  .block-content {
    flex: 1;
    padding: 12px 16px;
    font-size: 14px;
  }

  .block-footer {
    padding: 12px 16px;
    border-top: 1px solid $gray-300;
    flex-shrink: 0;
  }
}

// _list-group
.list-group-item {
  &.active {
    border-color: $list-group-border-color;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      bottom: -1px;
      width: $list-group-active-border-size;
      background-color: $list-group-active-border-color;
    }
  }

  .list-group-item-title {
    color: $body-color;
    margin-bottom: $list-inline-padding;
  }
}

.list-group-item,
.list-group-item:hover {
  z-index: auto;
}

// _button
.btn {
  box-shadow: 0px 2px 5px 0px #dbdbde;
}

.btn-round {
  border-radius: 50%;
}

.btn-round-single-icon {
  width: 1.7rem;
  height: 1.7rem;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  border-radius: 50%;
}

// _utils
.shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.mapboxgl-ctrl-group {
  border: 0.4375px solid #d5d8db;
  border-radius: 5px !important;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: 0px 0.875px 1.75px rgba(15, 15, 15, 0.1) !important;
}

.mapboxgl-ctrl-group.hiboo-notification {
  border: 1px solid $blue400;
  box-shadow: none !important;
}

.mapboxgl-ctrl-group > button {
  // width: 27.125px !important;
  // height: 25.0625px !important;
  background-position: center;
  border-bottom: none !important;
}

.mapboxgl-ctrl-group > button + button {
  border-top: none !important;
}

// Sometimes you have to take the front wheel. We will decide height based on snap points for bottomsheet.
[data-rsbs-scroll] {
  height: 100%;
}
